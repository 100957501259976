<template>
  <div class="course">
    <div class="course-con" v-if="courseData.length>0">
      <div class="item" v-for="(item,index) in courseData" :key="index">
        <router-link v-if="item.community_group"
                     :to="{path:`/activityDetail/${item.community_group.id}`}">

          <div class="img">
            <img :src="item.community_group.cover_image_url" alt="">
          </div>
          <div class="course-desc">
            <div class="course-title">
              <span>{{translateTag(item.community_group.category_id)}}</span>
              {{item.community_group.title}}
            </div>
            <div class="course-time">
              开始时间 {{item.community_group.started_at}}
            </div>
            <!--          <div class="course-last-line">-->
            <!--            <div class="course-enrolls">{{item.course.enrolls}}人已报名</div>-->
            <!--            <div class="call-service">请联系微信小助手参与课程</div>-->
            <!--          </div>-->
          </div>
        </router-link>
      </div>

    </div>
    <NoContent v-else :data="noContentData"></NoContent>
    <el-pagination
        @size-change="getCourseData"
        @current-change="getCourseData"
        v-if="coursePagination"
        :hide-on-single-page="true"
        background
        layout="prev, pager, next"
        :page-size="coursePagination.per_page"
        :total="coursePagination.total">
    </el-pagination>
  </div>
</template>

<script>
  import * as Api from '@/api/user/user'
  import NoContent from '@/components/NoContent/NoContent'
  import { activityType } from '@/enums/API'
  import {formatMoment} from '@/utils/utils'


  export default {
    name: 'MyActivity',
    data () {
      return {
        courseData: [],
        coursePagination: {},
        noContentData: {
          pic: 'course_pic',
          tip: '您没有参加任何活动哦~'
        },
      }
    },
    computed: {},
    mounted () {
      this.getCourseData()
    },
    methods: {
      getCourseData (page = 1) {
        let params = {
          page: page
        }
        Api.myActivity(activityType.trainingCamp, params, (res, page) => {
            res.forEach(item=>{
                item.community_group.started_at = formatMoment(item.community_group.started_at, 'YYYY-MM-DD')
            })
          this.courseData = res
          this.coursePagination = page
        })
      },
      getPastData (page = 1) {
        this.$emit('getPastData', page)
      },
      translateTag (id) {
        let types = this.$store.state.common.categoryTypes
        let tag = types.find(item => {
          return item.id === id
        })
        return tag.abbr
      }
    },
    components: {
      NoContent
    }
  }
</script>

<style lang="less" scoped>
  /*@import '../../../assets/style/lib-base.less';*/

  .course {
    padding: 10px 40px;
    height: 500px;
    overflow: auto;
    margin-top: 50px;

    & > .course-con {
      overflow-y: auto;

      & > .item {
        width: 100%;
        height: 138px;

        & > a {
          height: 138px;
          display: flex;
          align-items: center;
          cursor: pointer;

          & > .img {
            width: 176px;
            height: 99px;
            margin-right: 20px;
            border-radius: 3px;
            overflow: hidden;

            & > img {
              width: 100%;
              max-height: 99px;
            }
          }

          & > .course-desc {
            text-align: left;
            width: 75%;

            & > .course-title {
              display: flex;
              align-items: center;

              & > span {
                background: #667482;
                color: #fff;
                padding: 2px 4px;
                margin-right: 8px;
                font-size: @fontSizeCon;
              }

              font-size: @fontSizeTitle;
              color: #333;
              font-weight: bold;
            }

            & > .course-time {
              font-size: @fontSizeSubTitle;
              color: #5A5C63;
              margin: 6px 0 30px;
            }

            & > .course-price {
              color: @papaRed;
              font-size: @fontSizeTitle;
              font-weight: 600;
            }

            & > .course-last-line {
              display: flex;
              justify-content: space-between;

              & > .course-enrolls {
                color: #999;
                font-size: @fontSizeCon;
              }

              & > .call-service {
                background: #EDF9F3;
                color: @papaGreen;
                font-size: @fontSizeCon;
                cursor: pointer;
              }
            }
          }
        }
      }

      .item:hover {

        background: #F4FDF8;
      }
    }
  }

  .is-active {
    color: @papaGreen;
  }

  .el-pagination {
    margin-top: 20px;
    float: right;
  }
</style>
